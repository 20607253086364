<template>
  <div :class="htmlClasses">
    <div class="img-zoom">
      <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <circle stroke="#ffffff" fill="transparent" stroke-width="2" cx="10.5" cy="10.5" r="8.5"></circle>
        <line x1="17.5" y1="17.5" x2="22.5" y2="22.5" id="Line" stroke="#ffffff" stroke-width="2" stroke-linecap="square"></line>
      </svg>
      <div>Agrandir</div>
    </div>
    <Loader v-if="isLoading" />
    <img :src="src" @load="loaded" />
  </div>
</template>

<script>
import Loader from './Loader'

export default {
  name: 'ImageLoader',
  props: ['src'],
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    htmlClasses() {
      return {
        'image-load': true,
        'is-loading': this.isLoading
      };
    }
  },
  methods: {
    loaded() {
      this.isLoading = false;
    },
  }
}
</script>

<style>
.image-load {
  margin-bottom: 1rem;
  position: relative;
}

.image-load:hover {
  cursor: zoom-in;
}

.image-load.is-loading {
  background: #f0f0f0;
}

.image-load img {
  width: 100%;
}
/*
.image-load:hover .img-zoom {
  display: flex;
}
*/
.img-zoom {
  display: none;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(0,0,0,0.7);
  padding: 8px 16px;
  border-radius: 50px;
  color: #fff;
}
.img-zoom svg {
  margin-right: .5rem;
}
</style>
